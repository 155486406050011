<template>
  <div class="config-integrations-zabbix-create">
    <h1 class="titulo-card fs-4">Integrações / Zabbix / Registrar nova integração</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="form.active" id="active">
            <label class="form-check-label" for="active">
              Ativo
            </label>
          </div>
          <div class="form-group">
            <label for="description">Identificação</label>
            <input type="text" id="description" v-model="form.description" class="form-control" placeholder="">
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-group">
            <label for="endpoint">Endpoint</label>
            <input type="text" id="endpoint" v-model="form.endpoint" class="form-control" placeholder="https://seu-servidor-zabbix.com.br/zabbix/api_jsonrpc.php">
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-group">
            <label for="login">Usuário</label>
            <input type="text" id="login" v-model="form.login" class="form-control" placeholder="">
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-group">
            <label for="password">Senha</label>
            <input type="text" id="password" v-model="form.password" class="form-control" placeholder="">
          </div>
        </div>
        <div class="col-12">
          <div class="d-grid gap-2">
            <button type="button" @click="btnCreateClick()" class="btn btn-primary">
              <font-awesome-icon :icon="['fas', 'check']" fixed-width class="me-1"/>
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import loading from '@/modules/loading';
import dialogo from '@/modules/dialogo';

export default {
  name: 'ConfigIntegrationsZabbixCreate',
  data() {
    return {
      form: {
        active: true,
        description: '',
        endpoint: '',
        login: '',
        password: '',
      }
    }
  },

  methods: {
    create() {
      loading(true);
      api.post(`/integrations/zabbix`, this.form).then(() => {
        loading(false);
        this.$router.go(-1);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    ////////////////////////////////
    // interface item click handlers
    btnCreateClick() {
      this.create();
    },
    // interface item click handlers
    ////////////////////////////////

  },
  created() {
  }
}


</script>
